@font-face {
  font-family: "AlegreyaBlack";
  src: local("AlegreyaBlack"),
    url(./assets/fonts/Alegreya_SC/AlegreyaSC-Black.ttf) format("truetype");
}

@font-face {
  font-family: "AlegreyaBlack-Bold";
  src: local("AlegreyaBlack-Bold"),
    url(./assets/fonts/Alegreya_SC/AlegreyaSC-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "AlegreyaExtraBold";
  src: local("AlegreyaExtraBold"),
    url(./assets/fonts/Alegreya_SC/AlegreyaSC-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "PTSerifCap";
  src: local("PTSerifCap"),
    url(./assets/fonts/PT_Serif_Caption/PTSerifCaption-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Mochiy-bold";
  src: local("Mochiy-bold"),
    url(./assets/fonts/Mochiy_Pop_P_One/MochiyPopPOne-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Alegreya-Medium";
  src: local("Alegreya-Medium"),
    url(./assets/fonts/Alegreya_SC/AlegreyaSC-Medium.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: y mandatory;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
