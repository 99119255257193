.header-container-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #05a750;
  background-color: #171321;
  min-height: 100px;
  width: 100%;
  transition: background-color 1s ease-in-out;
}

.header-container-light {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #000000;
  background-color: #ffffff;
  min-height: 100px;
  width: 100%;
  transition: background-color 1s ease-in;
}

.main-nav {
  display: flex;
  gap: 5rem;
}

.main-nav a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  font-family: "PTSerifCap";
}

.header-title-dark {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  font-family: "AlegreyaExtraBold";
  cursor: pointer;
  width: 150px;
  justify-self: center;
  margin-left: auto;
}

.header-title-light {
  font-size: 2rem;
  font-weight: bold;
  color: #110e0e;
  font-family: "AlegreyaExtraBold";
  cursor: pointer;
  width: 150px;
  justify-self: center;
  margin-left: auto;
}

@media (max-width: 420px) {
  .animation-nav-container {
    display: none;
  }
}
