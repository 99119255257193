.Applight {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  transition: all 1s ease-in;
}

.Appdark {
  display: flex;
  flex-direction: column;
  background-color: #171321;
  width: 100%;
  transition: all 1s ease-in-out;
}

.main-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  flex-direction: column;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
}

.section-profile-container {
  display: block;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  position: relative;
}

.section-my-experience {
  display: block;
  width: 100%;
  height: calc(100% - 0px);

  background-color: #0c314a;
  scroll-snap-align: end;
}

@media (max-width: 420px) {
  .main-container {
    display: flex;
    width: 100%;
    height: calc(100vh);
    flex-direction: column;
  }
}
