.icon-item {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.icon-item:hover {
  background-color: #05a750;
  transition: all 0.3s ease-in-out;
}
