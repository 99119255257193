.section-profile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  position: relative;
}

.profile-img {
  border-radius: 50%;
  height: 450px;
  user-select: none;
  pointer-events: none;
}

.defination-person-dark {
  font-family: "Mochiy-bold";
  font-size: 3.5rem;
  color: #ffffff;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.defination-person-light {
  font-family: "Mochiy-bold";
  font-size: 3.5rem;
  color: #000000;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.slider-button {
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.radio-container {
  cursor: pointer;
  height: calc((300px - 10rem) / 6 + 1rem);
  margin: 0;
  position: relative;
}

.bio-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.slider-button-list {
  display: flex;
  justify-content: space-evenly;
  width: 450px;
  align-items: center;
}

.slider-label {
  font-size: 0.625rem;
}

.slider-label::before {
  border: 1px solid pink;
  z-index: 10;
  border-radius: 50%;
  content: "";
  display: block;
  height: 0;
  left: 50%;
  padding-bottom: calc(0.75rem - 1px);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
}

.slider-label::after {
  background: pink;
  opacity: 0;
  transition: 200ms opacity linear;
  border-radius: 50%;
  content: "";
  display: block;
  height: 0;
  left: 50%;
  padding-bottom: calc(0.75rem - 1px);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
}

:checked + .slider-label::after {
  opacity: 1;
}

.bioMessages-dark {
  font-family: "Mochiy-bold";
  font-size: 1.5rem;
  color: #ffffff;
  width: calc(100% - 200px);
  height: 200px;
  padding: 0 20px;
  text-align: center;
}

.bioMessages-light {
  font-family: "Mochiy-bold";
  font-size: 1.5rem;
  color: #0e0d0d;
  width: calc(100% - 200px);
  height: 200px;
  padding: 0 20px;
  text-align: center;
}

#profile-image-container {
  width: 450px;
  height: 450px;
  border-radius: 50%;
  box-shadow: rgb(9 166 79 / 40%) 0px 0px 13px 5px;
  margin-left: 5%;
}
#icon-container-animation-div {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.arrow-image {
  width: 200px;
  transform: rotate(161deg);
}

.arrow-img-container {
  position: absolute;
  top: 100px;
  left: 165px;
}

@media (max-width: 768px) {
  .section-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .profile-img {
    border-radius: 50%;
    height: 250px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  #profile-image-container {
    width: 250px;
    height: 250px;
    margin-left: 0;
    margin-top: 5%;
  }
  .defination-person {
    font-size: 2.5rem;
  }
}

@media (max-width: 420px) {
  .section-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow-x: hidden;
  }
  .profile-img {
    border-radius: 50%;
    height: 250px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  #profile-image-container {
    width: 250px;
    height: 250px;
    margin-left: 0;
    margin-top: 5%;
  }
  .defination-person {
    font-size: 1.5rem;
  }
  .bioMessages-dark {
    font-family: "Mochiy-bold";
    font-size: 18px;
    color: #ffffff;
    width: calc(100% - 30px);
    height: 100px;
    padding: 30px 20px;
    text-align: center;
  }

  .bioMessages-light {
    font-family: "Mochiy-bold";
    font-size: 18px;
    color: #161616;
    width: calc(100% - 30px);
    height: 100px;
    padding: 30px 20px;
    text-align: center;
  }
  .slider-button-list {
    width: 100%;
  }
}
